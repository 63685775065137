import type Country from "@interfaces/Country";
import { countries } from "@lib/constants/countries";
import { useRouter } from "next/router";

type UseCountrySelector = () => [Country[], string];

export const useCountriesSelector: UseCountrySelector = () => {
    const { locale } = useRouter();
    const countriesSorted = countries.sort((a, b) => a.name.localeCompare(b.name));

    return [countriesSorted, locale];
};
