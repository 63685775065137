const getIconPath = (name: string) => `/assets/flags/${name}.svg`;

export const countries = [
    {
        id: "cs-CZ",
        name: "Česko",
        icon: getIconPath("czech"),
        link: "https://cz.ecco.com/cs-CZ",
    },
    {
        id: "da-DK",
        name: "Danmark",
        icon: getIconPath("denmark"),
        link: "https://dk.ecco.com/da-DK",
    },
    {
        id: "fi-FI",
        name: "Suomi",
        icon: getIconPath("finland"),
        link: "https://fi.ecco.com/fi-FI",
    },
    {
        id: "et-EE",
        name: "Eesti",
        icon: getIconPath("estonia"),
        link: "https://ee.ecco.com/et-EE",
    },
    {
        id: "hu-HU",
        name: "Magyarország",
        icon: getIconPath("hungary"),
        link: "https://hu.ecco.com/hu-HU",
    },
    {
        id: "fr-FR",
        name: "France",
        icon: getIconPath("france"),
        link: "https://fr.ecco.com/fr-FR",
    },
    {
        id: "de-DE",
        name: "Deutschland",
        icon: getIconPath("germany"),
        link: "https://de.ecco.com/de-DE",
    },
    {
        id: "sv-SE",
        name: "Sverige",
        icon: getIconPath("sweden"),
        link: "https://se.ecco.com/sv-SE",
    },
    {
        id: "nl-NL",
        name: "Nederland",
        icon: getIconPath("netherlands"),
        link: "https://nl.ecco.com/nl-NL",
    },
    {
        id: "lt-LT",
        name: "Lietuva",
        icon: getIconPath("lithuania"),
        link: "https://lt.ecco.com/lt-LT",
    },
    {
        id: "lv-LV",
        name: "Latvija",
        icon: getIconPath("latvia"),
        link: "https://lv.ecco.com/lv-LV",
    },
    {
        id: "de-CH",
        name: "Schweiz - Deutsch",
        icon: getIconPath("switzerland"),
        link: "https://ch.ecco.com/de-CH",
    },
    {
        id: "fr-CH",
        name: "Suisse - Français",
        icon: getIconPath("switzerland"),
        link: "https://ch.ecco.com/fr-CH",
    },
    {
        id: "pl-PL",
        name: "Polska",
        icon: getIconPath("poland"),
        link: "https://pl.ecco.com/pl-PL",
    },
    {
        id: "nl-BE",
        name: "België - Nederlands",
        icon: getIconPath("belgium"),
        link: "https://be.ecco.com/nl-BE",
    },
    {
        id: "fr-BE",
        name: "Belgique - Français",
        icon: getIconPath("belgium"),
        link: "https://be.ecco.com/fr-BE",
    },
    {
        id: "de-AT",
        name: "Österreich",
        icon: getIconPath("austria"),
        link: "https://at.ecco.com/de-AT",
    },
    {
        id: "en-MY",
        name: "Malaysia",
        icon: getIconPath("malaysia"),
        link: "https://my.ecco.com/",
    },
    {
        id: "en-SG",
        name: "Singapore",
        icon: getIconPath("singapore"),
        link: "https://sg.ecco.com/",
    },
    {
        id: "en-IN",
        name: "India",
        icon: getIconPath("india"),
        link: "https://in.ecco.com/",
    },
    {
        id: "ko-KR",
        name: "대한민국(한국어)",
        icon: getIconPath("korea"),
        link: "https://kr.ecco.com/",
    },
    {
        id: "ja-JP",
        name: "日本 (日本語)",
        icon: getIconPath("japan"),
        link: "https://jp.ecco.com/",
    },
    {
        id: "en-US",
        name: "United States",
        icon: getIconPath("us"),
        link: "https://us.ecco.com/",
    },
    {
        id: "en-CA",
        name: "Canada (English)",
        icon: getIconPath("canada"),
        link: "https://ca.ecco.com",
    },
    {
        id: "fr-CA",
        name: "Canada (Français)",
        icon: getIconPath("canada"),
        link: "https://ca.ecco.com/fr-CA",
    },
    {
        id: "en-GB",
        name: "United Kingdom",
        icon: getIconPath("uk"),
        link: "https://gb.ecco.com/en-GB",
    },
    {
        id: "nb-NO",
        name: "Norge",
        icon: getIconPath("norway"),
        link: "https://no.ecco.com/nb-NO",
    },
    {
        id: "sk-SK",
        name: "Slovensko",
        icon: getIconPath("slovakia"),
        link: "https://sk.ecco.com/sk-SK",
    },
    {
        id: "en-IE",
        name: "Ireland",
        icon: getIconPath("ireland"),
        link: "https://ie.ecco.com/en-IE",
    },
    {
        id: "sl-SI",
        name: "Slovenija",
        icon: getIconPath("slovenia"),
        link: "https://si.ecco.com/sl-SI",
    },
    {
        id: "hr-HR",
        name: "Hrvatska",
        icon: getIconPath("croatia"),
        link: "https://hr.ecco.com/hr-HR",
    },
    {
        id: "pt-PT",
        name: "Portugal",
        icon: getIconPath("portugal"),
        link: "https://pt.ecco.com/pt-PT",
    },
    {
        id: "es-ES",
        name: "España",
        icon: getIconPath("spain"),
        link: "https://es.ecco.com/",
    },
    {
        id: "en-AU",
        name: "Australia",
        icon: getIconPath("australia"),
        link: "https://au.ecco.com",
    },
];
